.matches{
    display: block;
    padding: 50px 20px;
    max-width: 95% !important;
}
.match-header{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.matchBanner{
    width: 100%;
    height: auto;
}
.matchBanner img{
    width: 100%;
    height: auto;
}
.matchList .matchBox{
    flex: 0 calc(33.33% - 8px)
}

@media screen and (max-width: 980px) {
    .matchList .matchBox{
        flex: 0 calc(50% - 8px);
    }
    .matchBanner{
        height: 380px;
    }
    .matchBanner img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 80% 50%;
    }
}
@media screen and (max-width: 480px) {
    .matchList .matchBox{
        flex: 0 100%;
    }
    .matchBanner{
        height: 280px;
    }
    .matchBanner img {
        height: 100% !important; 
        width: 100%;
        object-fit: cover;
        object-position: 80% 50%;
    }
}