.match-stats {
    display: block;
    padding: 50px 0;
}
.match-graph{
    max-width: 100%;
    width: 95%;
    margin: 0 auto 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.match-linegraph{
    padding: 20px;
    border-radius: 10px;
    background-color: #eee;

    flex: 1 1 auto;
}
.match-linegraph > div{
    width: 100%;
}
@media screen and (max-width: 980px){
    .match-graph{
        flex-wrap: wrap;
    }
    .match-linegraph > div{
        height: auto;
    }
}


.session-statistic{
    padding: 20px;
    border-radius: 10px;
    background-color: #eee;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex: 1 1 calc(50% - 20px);
    justify-content: center;
}
.session-statistic .stats{
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e0e0e0;
    padding: 20px;
    flex: 0 calc(50% - 20px);
}
.session-statistic .stats .count{
    font-size: 2.5rem;
    font-weight: 700;
}
.session-statistic .stats .label{
    font-size: 14px;
}

@media screen and (max-width: 480px){
    .match-graph{
        flex-wrap: wrap;
    }
    .session-statistic .stats{
       justify-content: flex-start;
       padding: 10px;
    }
    .session-statistic .stats .count{
        font-size: 20px;
        font-weight: 700;
    }
}
.stats-container{
    max-width: 100%;
    width: 95%;
    margin: 0 auto;
    display: block;
}




.match-stats .title-header{
    max-width: 100%;
    width: 95%;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
}
.stats-list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}
.stats-list > .stats-box{
    background-color: var(--third-color);
    color: var(--text-w-color);
    flex: 0 calc(25% - 10px);
    padding: 10px;
    border-radius: 10px;
}
.stat-matchId{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.stat-matchId p{
    font-size: 14px;
}
.stat-matchId h3{
    font-size: 20px;
}
.stat-status{
    display: flex;
    text-align: right;
    padding-top: 5px;
}
.stat-status > div{
    flex: 0 50%;
}
.stat-status .total{
    font-size: 28px;
    font-weight: 600;
}
.stat-status small{
    font-size: 11px;
}

.stats-list > table{
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
}

.match-date{
    flex: 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.stats-pagination{
    width: 95%;
    margin: 20px auto ;
    display: flex;
    justify-content: center;
    
}
.stats-pagination button{
    height: 40px;
}
.stats-pagination button:not(:disabled){
    background-color: var(--accent-color);
    color: var(--third-color);
}
.stats-pagination button:is(:disabled){
    background-color: var(--third-color);
    color: var(--white-30);
    cursor:not-allowed;
}

.stats-pagination .MuiSelect-select, .optionPerItem .MuiSelect-select{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.optionPerItem, .itemsperpage{
    display: flex;
    color: var(--text-w-color);
    align-items: center;
    gap: 10px
}
.select-monthly{
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}
@media screen and (max-width: 980px) {
    .stats-list > .stats-box{
        background-color: var(--third-color);
        color: var(--text-w-color);
        flex: 0 calc(33.33% - 10px);
        padding: 10px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 767px) {
    .stats-list > .stats-box{
        background-color: var(--third-color);
        color: var(--text-w-color);
        flex: 0 calc(50% - 10px);
        padding: 10px;
        border-radius: 10px;
    }
    .match-stats .title-header{
        flex-wrap: wrap;
        gap: 10px;
    }
    .optionPerItem{
        justify-content: space-between;
        flex: 0 100%;
    }
}

@media screen and (max-width: 450px) {
    .stats-list > .stats-box{
        background-color: var(--third-color);
        color: var(--text-w-color);
        flex: 0 calc(100%);
        padding: 10px;
        border-radius: 10px;
    }
    .stat-matchId p{
        font-size: 12px;
    }
    .stat-matchId h3{
        font-size: 18px;
    }
    .stat-status {
        flex-wrap: wrap;
    }
    .stat-status > div{
        flex: 0 100%;
    }
    .stat-matchId{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-direction: column;
    }
}