.blog-section{
    padding: 0px 0 70px;
}
.blog-section .wrapper{
    max-width: 95%;
    margin: 0 auto;
}
.blogs{
    display: grid;
    grid-template-columns: 1.3fr 1.3fr; /* Two columns, the left one is twice as wide as the right one */
    grid-template-rows: .5fr .5fr; /* Two rows of equal height */
    gap: 20px; /* Space between columns */
    padding: 20px;
}

.blogs .blog-post.div1{
    grid-row: 1 / 3;
}

.blogs .blog-post{
    line-height: 0;
}

.blogs > div{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: aliceblue;
}

.blog-post .blog-inner{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.blogs .blog-post.div1 img{
    line-height: 0;
}

.blogs .blog-post img{
    transition: all 200ms linear;
}

.blogs .blog-post:not(.div1) img{
    object-fit: cover;
    object-position: 50% 70%;
    margin-top: -60px;
}

.blogs .blog-post .blog-content{
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 20px;
    line-height: normal;
    z-index: 2;
    color: var(--text-w-color);
}

.blogs .blog-post .blog-content p{
    line-height: 1.4em;
}
.blog-post .blog-inner::after{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.8044467787114846) 6%, rgba(0,0,0,0.6475840336134453) 30%, rgba(0,0,0,0) 100%);
    content: "";
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 1000ms linear;
}

.blog-section h2{
    margin-bottom: 20px;
}

.blog-post:hover .blog-inner::after{
    background: rgb(255,187,0);
    background: linear-gradient(0deg, rgba(255,187,0,0.9) 16%, rgba(255,187,0,0.30584733893557425) 70%, rgba(255,187,0,0) 100%);
}

.blog-post:hover .blog-inner{
    cursor: pointer;
}

.blog-post:hover .blog-inner img{
    transform: scale(1.1);
}
.blog-post:hover .blog-inner .blog-content{
    color: var(--third-color);
}

.blog-post .blog-content a{
    color: var(--accent-color);
    text-decoration: none;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.blog-page .pagination button{
    color: var(--text-w-color);
}

.blog-post .blog-content a > span{
    opacity: 0;
    margin-left: -13px;
    transition: all ease-in-out 0.4s;
}
.blog-post:hover .blog-content a{
    color: var(--third-color);
    font-weight: 600;
}
.blog-post:hover .blog-content a > span{
    opacity: 1;
    margin-left: 0px;
}

@media screen and (max-width: 980px) {
    .blogs{
       display: flex;
       flex-wrap: wrap;
       gap: 10px
    }
    .blogs > div{
        flex: 1 calc(50% - 10px);
    }
    .blog-page .blogs>div.blog-post{
        background-color: #000;
    }
    .blogs .blog-post img, .blogs .blog-post:not(.div1) img{
        object-fit: cover;
        object-position: 50% 50%;
        margin-top: unset;
    }
    .blog-post .blog-inner::after{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 36%, rgba(0,0,0,0.6475840336134453) 70%, rgba(0,0,0,0) 100%);
        content: "";
        top: 0;
        left: 0;
        z-index: 1;
        transition: all 1000ms linear;
    }
    .blog-page .blogs>div.blog-post:hover{
        background-color: var(--accent-color);
    }
  
}
@media screen and (max-width: 768px) {
    .blog-detail-page{
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 480px) {
  
    .blogs{
       display: flex;
       flex-wrap: wrap;
       gap: 10px
    }
    .blogs > div{
        flex: 1 100%;
    }
    .blogs .blog-post img, .blogs .blog-post:not(.div1) img{
        object-fit: cover;
        object-position: 50% 50%;
        margin-top: unset;
    }
}


/*BLOG PAGE*/
.blog-page{
    padding: 50px 0;
}

.blog-page .blogs{
     display: flex;
     flex-wrap: wrap;
     gap: 20px;
     justify-content: center;
}
.blog-page .blogs > div.blog-post{
    flex: 0 calc(33.333% - 20px);
}
.blog-page .blogs > div.blog-post img{
    margin-top: 0 !important;
    transform: scale(1.1);
}
@media screen and (max-width: 980px) {
    .blog-page .blogs>div.blog-post{
        flex: 0 calc(50% - 20px);
        min-height: 380px;
    }
    .blog-page .blogs>div.blog-post h2{
        font-size: 20px;
    }

    .blogBanner{
        height: 380px;
    }
    .blogBanner img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 80% 50%;
    }
}

@media screen and (max-width: 480px) {
    .blogBanner{
        height: 280px;
    }
    .blogBanner img{
        height: 100%;
    }
    .blog-page .blogs>div.blog-post{
        
      flex: 0 100%;
    }
}
.sort-dropdown{
    display: block;
    width: 95%;
    margin: 0 auto;
    text-align: right;
}
.sort-dropdown > div{
    flex-direction: row;
    color: var(--text-w-color);
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}



.blog-details{
    padding: 50px 0;
    width: 95%;
    margin: 0 auto;
}
.blog-detail-page{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
}
.blog-detail-page .main-content{
    flex: 0 calc(70% - 20px);
    padding: 10px;
    background-color: var(--third-color);
    border-radius: 10px;
    color: var(--text-w-color);
}
.blog-detail-page .main-content img{
    height: 450px !important;
    object-position: 50% 25%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}
.blog-detail-page .main-content h1{
    margin-bottom: 20px;
}

.blog-detail-page .sidebar{
    flex: 0 calc(30% - 20px);
    padding: 10px;
    color: var(--text-w-color);
}
.blog-detail-content{
    padding: 15px;
}
.blog-detail-content p{
    line-height: 1.6em;
}
.blog-detail-page .sidebar ul{
    padding: 20px 0;
}
.blog-detail-page .sidebar li a{
    display: flex;
    gap: 10px;
    color: var(--text-w-color);
    text-decoration: none;
    align-items: center;
}
.blog-detail-page .sidebar li{
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px dashed var(--white-30);
}
.blog-detail-page .sidebar li a img{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
}
.blog-detail-page .sidebar li a:hover{
    color: var(--accent-color);
}


@media screen and (max-width: 480px) {
    .blog-detail-page .main-content img {
        height: 310px !important;
    }
}



/*BLOG LIST*/
.blog-container {
    padding: 50px 0;
}
.blog-container .blog-wrapper{
    background-color: #eee;
    width: 95%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 15px;
}
.bloglist-container table{
    display: block;
    width: 100%;
}

.bloglist-container table thead, .bloglist-container table tbody{
    width: 100%;
    display: table;
}

.blog-header{
    width: 95%;
    margin: 0 auto;
    padding: 20px 0;
    color: var(--text-w-color);
}

.blog-wrapper select{
    padding: 10px 20px;
    border-radius: 8px 0px 0px 8px;
}
.blog-wrapper select + button{
    padding: 10px 10px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #000;
    color: #000;
    cursor: pointer;
}