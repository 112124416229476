footer{
    padding: 15px 0;
    background-color: var(--third-color);
}
footer .wrapper{
    width: 95%;
    margin: 0 auto;
}
footer .row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
footer .row > div{
    flex: 0 calc(33.33% - 10px);
}
.social{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.social > a{
    text-decoration: none;
    line-height: normal;
}
.footer-logo {
    text-align: right;
}
.footer-logo img{
    max-width: 120px;
    margin-right: 0;
}

@media screen and (max-width: 480px){
    footer .row{
        flex-direction: column-reverse;
    }
}