
.liveVisit{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9999;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
}
.liveVisit > small{
    background-color: #e73a3f;
    color: #fff;
    font-size: 14px;
    padding: 2px;
    border-radius: 2px;
}
.liveVisit span {
    background-color: #000000b5;
    color: #fff;
    font-size: 14px;
    padding: 2px;
    border-radius: 2px;
    line-height: 0;
    width: max-content;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.liveVisit span > img{
    fill: white;
    width: 100%;
    max-width: 20px;
}

.live-container{
    padding: 50px 0;
    max-width: 95%;
    width: 90%;
    margin: 0 auto;
}
.live-container .video-container{
    margin-left: unset;
    margin-right: unset;
}

.live-wrapper{
    display: grid; 
    grid-template-columns: 1.8fr 0.7fr; 
    grid-template-rows: 1fr; 
    gap: 10px; 
    grid-template-areas: 
      "Live Chat"; 
}
.live-wrapper .video-wrapper{
    grid-area: Live;
}
.live-wrapper .video-wrapper > div{
    max-width: 100%;
}
.live-wrapper .liveChat{
    grid-area: Chat;
    padding: 10px;
    background-color: var(--dark-gray);
    border-radius: 10px;
}
.live-wrapper .liveChat iframe{
    padding-top: 20px;
    height: calc(100% - 30px);
    border: 0;
    width: 100%;

}

.live-container .recent-matches .wrapper{
    max-width: 100%;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .live-wrapper{
        display: flex;
        flex-wrap: wrap;
    }
    .live-wrapper > div{
        flex: 0 100%;
    }
    .live-wrapper .video-wrapper{
        height: 355px;
    }
    .live-wrapper .video-container{
        height: 355px;
        margin-top: 0;
        position: relative;
        z-index: 1;
    }
    .live-wrapper  .liveChat {
        height: 350px;
    }
    .live-container .video-container.iframe-fullscreen{
        position: fixed;
        height: 100%;
        z-index: 9999;
    }
}