*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
:root{
    --primary-color: #24080d;
    --secondary-color: #600202;
    --third-color: #421414;
    --accent-color: #ffbb00;
    --accent-color-50: #ffbb00a4;
    --text-w-color: #fff;
    --white-30: rgba(255, 255, 255, 0.3);
    --text-b-color: #000; 
    --dark-gray: #242424;
    --black-60: rgba(0,0,0,0.6);
    --black-20: rgba(0,0,0,0.2);
    
}
body{
    font-family: "Montserrat", system-ui;
    background-color: var(--primary-color) ;
}
#root{
    overflow-x: hidden;
}
.container{
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    display: block;
    position: relative;
}

.fluid-container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
}

#main-body{
  
    margin-left: 310px;
    position: relative;
    transition: all 0.8s;
}
#main-body.body-collapse{
    margin-left: 0;
}


@media screen and (max-width: 1024px) {
    #main-body{
        margin-left: 0;
    } 
}

.main-wrapper{
    padding: 70px 0;
}
.match-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: var(--third-color);
}

.match-container::-webkit-scrollbar {
    width: 6px;

    background-color: #f5f5f5;
}

.match-container::-webkit-scrollbar-thumb {
    background-color: #cad311;
}

.matches {
    display: flex;

    padding: 20px 15px;

    font-family: "Montserrat", sans-serif;

    gap: 10px;
    max-width: 1280px;
    margin: 0 auto;
}

.matchCategory {
    flex: 0 220px;
}

.match-container {
    min-height: 300px;
    position: relative;

    padding-right: 0px;

    flex: 1;
}

.matchCategory ul {
    display: flex;

    flex-direction: column;

    margin: 0;
}

.matchCategory ul li {
    list-style: none;

    padding: 10px 15px;

    cursor: pointer;

    border-radius: 5px;

    display: flex;

    align-items: center;
}

.matchCategory ul li.active,
.matchCategory ul li:hover {
    background-color: var(--third-color);
    color: #fff;
}

.matchCategory ul li>img {
    margin-right: 10px;

    filter: invert(2);

    width: 20px;
}

.matchCategory ul li.active,
.matchCategory ul li:hover {
    background-color: var(--third-color);

    color: #fff;
}

.matchCategory ul li.active>img,
.matchCategory ul li:hover>img {
    filter: brightness(100);
}

.matchList {
    display: flex;

    flex-wrap: wrap;

    justify-content: flex-start;

    align-content: flex-start;

    gap: 8px;

    width: 100%;
    min-height: 300px;
}

.matchBox {
    display: flex;

    gap: 10px;

    border: 1px solid #333;

    padding: 15px;

    border-radius: 10px;

    flex: 0 calc(50% - 10px);

    justify-content: center;

    flex-wrap: wrap;

    background-repeat: no-repeat;

    background-position: center right;

    background-color: var(--third-color);

    color: #fff;

    align-items: center;
    transition: all 200ms linear;
}

.ESPORTS {
    background-image: url(../assets/images/esports-bg.png);
    transition: all 200ms linear;
}

.BASKETBALL {
    background-image: url(../assets/images/basketball-bg.png?v=1.2);
    transition: all 200ms linear;
}

.FOOTBALL {
    background-image: url(../assets/images/football-bg.png);
    transition: all 200ms linear;
}

.TENNIS {
    background-image: url(../assets/images/tennis-bg.png);
    transition: all 200ms linear;
}

.CRICKET {
    background-image: url(../assets/images/cricket-bg.png);
    transition: all 200ms linear;
}


.League {
    flex: 0 100%;

    font-weight: 600;

    color: #ffbb00;

    font-size: 20px;
}

.schedule {
    flex: 0 100%;

    display: flex;

    gap: 10px;

    color: #fff;

    margin-bottom: 10px;
}

.schedule>p {
    display: flex;

    align-items: center;

    gap: 5px;

    padding-bottom: 0 !important;
}

.matchinner {
    flex: 1;

    width: 100%;
}

.matchwrapper {
    flex: 0 100%;

    display: flex;

    align-items: center;

    justify-content: flex-start;
}

.matchwrapper .teams {
    flex: 0 auto;

    text-align: center;

    padding-top: 5px;

    padding-bottom: 5px !important;

    font-weight: 600;
}

.matchwrapper .versus {
    flex: 0 55px;

    text-align: center;

    color: #ffbb00;

    padding-bottom: 0 !important;
}

.matchBox:hover {
    background-color: var(--accent-color-50);

    cursor: pointer;
    background-size: 110%;
}
.matchBox:hover .League{
    color: var(--third-color);
}

.matchBox:hover p{
    color: var(--text-b-color);
}

.matchLive {
    flex: 1;
}

.matchLive>iframe {
    width: 100%;

    height: 100%;
}

.matchlink {
    display: flex;

    align-items: center;

    height: 100%;

    flex-direction: column;

    flex: 0 70px;

    justify-content: center;
}

.isLive {
    flex: 1;

    text-transform: uppercase;

    font-size: 14px;

    margin-bottom: 10px;
}

.isLive:before {
    content: "";

    width: 8px;

    height: 8px;

    background-color: #e73a3f;

    box-shadow: #e73a3f 0 0 8px 1px;

    display: inline-block;

    border-radius: 50%;

    margin-right: 4px;

    animation: blinking 1s linear infinite;
}
.isLive.hide{
    visibility: hidden;
}
.isLive.show{
    visibility: visible;
}
.btn-viewall{
    position: relative;
    display: block;
    background-color: var(--accent-color);
    padding: 12px 25px;
    text-decoration: none !important;
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 30px;
    border: 2px solid var(--accent-color);
    transition: all 200ms linear;
    margin: -20px auto 40px;
    text-align: center;
    max-width: max-content;
    transition: all 0.4s linear;
}
.btn-viewall:hover{
    background-color: var(--accent-color-50);
}
.loading{
    width: 100%;
    height: 100vh;
}
@keyframes blinking {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.6;
    }
}

.btn-link {
    background-color: rgb(119 20 20 / 57%);

    border: 1px solid #e73a3f;

    border-radius: 50%;

    width: 70px;

    height: 70px;

    flex: 0 70px;

    cursor: pointer;

    transition: all 200ms linear;

    padding: 0;
}

.btn-link:hover {
    background-color: #e73a3f;
}

.btn-link:hover img {
    filter: brightness(100);
}

@media only screen and (max-width: 980px) {
    .matchBox {
        flex: 0 100%;
    }

    .matchCategory {
        flex: 1;

        /* flex-direction: column; */

        overflow: scroll;
    }

    .matches {
        display: flex;

        padding: 20px 15px;

        font-family: "Montserrat", sans-serif;

        gap: 10px;

        flex-direction: column;
    }

    .matchCategory ul {
        display: flex;

        margin: 0;

        padding: 0 !important;

        flex-direction: row;
    }

    .matchCategory ul li {
        list-style: none;

        padding: 10px 22px;

        cursor: pointer;

        border-radius: 5px;

        max-width: fit-content;

        display: flex;

        flex-direction: row;

        justify-content: center;

        /* width: 220px; */
    }
}



.match-lightbox {
    width: 100%;

    height: 100%;
    padding: 50px 0px 30px;
    position: relative;
    margin: 0 auto;
}

.matchInfo h3,
.matchInfo p {
    text-align: center;

    color: #fff;
}

.match-lightbox iframe {
    max-width: 620px;

    width: 100%;

    height: 380px;

    border: none;

    display: block;

    margin: 0 auto;
}

.match-lightbox>button {
    position: absolute;

    right: 0;

    top: 0;

    border: 0px solid #fff;

    background-color: #000;

    color: #fff;

    padding: 6px 8px;

    width: 30px;

    height: 30px;

    font-size: 18px;

    cursor: pointer;
}

.match-lightbox>button:hover {
    background-color: #e73a3f;
}

.box-spinner {
    height: 100px;

    width: 100px;

    border-radius: 50%;

    border: 3px solid;

    border-color: orange transparent;

    animation: spin 1s infinite ease-out;

    position: absolute;

    top: 45%;

    left: 45%;

    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 980px) {
    .box-spinner {
        top: 35%;

        left: 35%;
    }

    .match-container {
        min-height: 300px;
    }
}

.btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-link a {
    flex: 1 100%;
    text-align: center;
    height: 100%;
    line-height: 90px;
}

.btn-link img {
    width: 35px !important;
    max-width: unset;
}

#match-lighbox-overlay .box-spinner {
    top: 20%;
}


.pagination {
    display: block;
    text-align: center;
    padding: 30px 0;
    color: var(--text-w-color);
}

.pagination span {
    display: inline-block;
    padding: 0 20px;
}

.pagination a {
    border: 0;
    background-color: var(--third-color);
    color: #fff;
    padding: 10px 15px;
    border-radius: 30px;
    min-width: 85px;
    cursor: pointer;
    text-decoration: none;
    transition: all 200ms linear;
}

.pagination a:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
}

.pagination a.disabled {
    background-color: #291317;
    color: #664c51;
    cursor: not-allowed;
}



@media screen and (max-width: 480px) {
    .teams {
        font-size: 10px;
    }

    .League {
        font-size: 12px;
    }

    .schedule {
        font-size: 10px;
    }

    .isLive {
        font-size: 9px;
    }

    .isLive::before {
        width: 6px;

        height: 6px;
    }

    .versus {
        font-size: 10px;
    }

    .matchlink {
        flex: 0 50px;
    }

    .matchBox {
        background-position: 63% center;
        background-size: cover;
    }

    .btn-link {
        width: 50px;

        height: 50px;

        flex: 0 50px;
    }

    .btn-link a {
        flex: 1 100%;
        text-align: center;
        height: 100%;
        line-height: 72px;
    }
}

.livestream{
    display: block;
    margin: 0 auto;
}

.video-container{
    display: block;
    position: relative;
    max-width: 860px;
    height: 505px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #000;
    padding-bottom: 20px;
}
.video-container iframe{
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.fullscreenIcon{
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: all 1000ms linear;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
    font-family: sans-serif;
   width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: all 0.8s ease-in;
    z-index: 99999;
}

.fullscreenIcon img{
  max-width: 30px;
}
.fullscreenIcon small{
    font-size: 14px;
}



/* Fullscreen styles for mobile */
.iframe-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    border: none;
}

/* Hide overflow when in fullscreen mode */
body.iframe-fullscreen-mode {
    overflow: hidden;
}


@media screen and (max-width:  600px) {
    #root{
        height: 100vh;
    }
    .video-wrapper {
        height: 100vh;
        background: #000;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
   }
   .video-container{
    flex: 0 100%;
    margin-top: -25%;
   }
}


.iconfullscreen{
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(../assets/images/icon-fullscreen.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.iconfullscreen.in-fullscreen{
    background-image: url(../assets/images/exit-fullscreen.png);

}
.recent-matches{
    padding: 80px 0;
}
.recent-matches .slick-track{
    display: flex !important;
    flex-wrap: wrap;
}

.recent-matches .slick-slide {
    display: block;
    padding: 12px;
    height: auto;
}
.recent-matches .slick-slide > div, .recent-matches .slick-slide > div .recentMatchBox {
    height: 100%;
}
.recent-matches .wrapper{
    width: 95%;
    display: block;
    margin: 0 auto;
}
.recentMatchBox{
    background-color: var(--third-color);
    border-radius: 15px;
    padding: 10px;
    background-position: center right;
    transition: all 200ms linear;
}

.recentMatchBox .League{
 font-size: 14px;
 
}
.recentMatchBox .teams{
    font-size: 12px;
    color: #fff;
}
.recentMatchBox a{
    text-decoration: none;
    color: #fff;
}
.recentMatchBox:hover {
    transform: scale(1.05);
    cursor: pointer;
    border: 1px solid var(--accent-color);
}
.recent-matches .wrapper{
    position: relative;
}
.recent-matches .wrapper button{
    top: -15px !important;
    right: 0 !important;
    left: unset !important;
}
.recent-matches .wrapper button.slick-prev{
    right: 50px !important;
}
.matchesThumbnail{
    width: 100%;
    height: 200px;
    display: block;
    background-color: #000000a1;
    padding: 10px;
    position: relative;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
}
.matchesThumbnail .isLive{
    color: #fff;
}
.recentMatchBox .btn-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 200ms linear;
}
.recentMatchBox .btn-link a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.recentMatchBox:hover .btn-link{
    transform: translate(-50%, -50%) scale(1);
}


.recent-matches .wrapper h2{
    margin-bottom: 30px;
}
.recent-matches .slick-btn::before{
    display: none;
}

.recent-matches .wrapper .slick-btn {
    border: 1px solid var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    transform: unset;
    background-color: transparent;
    transition: all 200ms linear;
    top: -60px !important;
}

.recent-matches .slick-btn:focus{
    background-color: transparent;
}

.recent-matches .slick-btn > span, .recent-matches .slick-btn:focus > span{
    color: var(--accent-color);
}
.recent-matches .slick-btn:hover > span{
    color: var(--third-color);
}
.recent-matches .slick-btn:hover{
    background-color: var(--accent-color);
}
.recent-matches  .slick-dots li button:before, .recent-matches .slick-dots li.slick-active button:before{
    color: var(--accent-color);
}


.wrapper .MuiTypography-h2{
    padding: 0 20px;
}
.download-section{
    padding: 20px 0 50px;
    color: var(--text-w-color);
}
.download-section .wrapper{
    max-width: 100%;
    width: 95%;
    margin: 0 auto;
    padding: 0 20px;
}
.download-content {
    padding: 20px;
    border-radius: 10px;
    background-color: var(--black-20);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.download-content > div{
    flex: 0 calc(50% - 20px);
}
.download-image img{
    max-width: 100%;
    height: auto;
}
.download-copy p{
    line-height: 1.6em;
}
.download-copy h2{
    margin-bottom: 20px;
}
.download-copy p:not(p:last-of-type){
   margin-bottom: 10px;
}

@media screen and (max-width: 980px) {
   
    .download-content > div{
        flex: 0 100%;
    }
}


@media screen and (max-width: 480px) {
    h2{
        font-size: 28px !important;
    }
    .recent-matches .wrapper h2{
        width: 80%;
        font-size: 25px !important;
    }
}

