.banner-sec{
 
    display: block
}
.banners-wrapper{
    background-image: url(../assets/images/banner-background.jpg);
    width: 100%;
    min-height: 450px;
    display: block;
    background-size: cover;
    background-position: center 60%;
    border-radius: 0px;
    padding: 0 60px;
    position: relative;

}
.slick-container{
    display: block;
}
.arrowNav {
    position: absolute;
    bottom:35px;
    right: 55px;
}
.slick-prev:before, .slick-next:before{
    font-size: 30px !important;
}
.banner-slide{
    padding: 30px 20px 0;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
}
.banner-slide .matchinner{
    flex: 0 50%;
}
.banner-slide .matchImage{
    flex: 0 50%;
}
.banner-slide .matchImage img{
    max-width: 450px;
    height: auto;
    margin: 0 auto;
}
.banner-slide  .matchwrapper{
    padding: 20px 0;
    font-size: 40px;
    color: #fff;
    display: block;
    text-align: left;
    position: relative;
    max-width: fit-content;
}
.banner-slide  .matchwrapper .teams{
    text-align: left !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.banner-slide  .isLive {
    border:1px solid var(--accent-color);
    color: var(--text-w-color);
    padding: 4px 7px;
    border-radius: 30px;
    background-color: var(--accent-color-50);
}

.banner-slide .isLive:before {
    background-color: rgb(172, 0, 0);
    box-shadow: #86060a 0 0 8px 1px;
}
.banner-slide  .matchwrapper .versus{
    /* padding: 0 10px;
    position: absolute;
    left: 50%;
    font-size: 20px;
    top: 43%;
    transform: translateY(-50%, -50%);
    z-index: -1; */
    font-size: 14px;
    text-align: left;
    font-weight: 700;
}
.banner-slide  .watch-link > a{
    position: relative;
    display: inline-block;
    background-color: var(--accent-color);
    padding: 12px 15px;
    text-decoration: none !important;
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 30px;
    border: 2px solid var(--accent-color);
    transition: all 200ms linear;
}
.banner-slide  .watch-link > a:hover{
    background-color: var(--accent-color-50);
}
.banner-slide .liveNow{
    color: #fff;
    padding: 20px 0;;
}

.arrowNav .slick-btn::before{
    display: none;
}
.arrowNav{
    display: flex;
    gap: 10px;
}
.arrowNav .slick-btn {
    border: 1px solid var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    left: unset;
    top: unset;
    right: unset;
    transform: unset;
    background-color: var(--accent-color);
    transition: all 200ms linear;
}
.arrowNav .slick-btn:focus{
    background-color: var(--accent-color);
}

.arrowNav .slick-btn > span, .arrowNav .slick-btn:focus > span{
    color: var(--third-color);
}
.arrowNav .slick-btn:hover > span{
    color: var(--accent-color);
}
.arrowNav .slick-btn:hover{
    background-color: transparent;
}
.banners-wrapper  .slick-dots{
    bottom: 20px;
}
.banners-wrapper  .slick-dots li button:before, .banners-wrapper .slick-dots li.slick-active button:before{
    color: var(--accent-color);
}
@media screen and (max-width: 1180px) {
    .banner-slide {
        position: relative;
        padding-top: 90px;
    }
    .banner-slide .matchImage{
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 350px;
    }   
    .banner-slide .matchImage img{
        max-width: 100%;
    }
    .banners-wrapper::after{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(34deg, rgba(0,0,0,0.6811974789915967) 21%, rgba(0,0,0,0) 61%, rgba(0,0,0,0) 100%);content: "";
        top: 0;
        left: 0;
        z-index: 1;
    }
    .banners-wrapper > div{
        position: relative;
        z-index: 2;
    }
    .arrowNav {
        position: absolute;
        bottom: -5px;
        right: -25px;
    }
}

@media screen and (max-width: 980px) {
    .banners-wrapper{
        padding: 0 20px;
    }
    .arrowNav {
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
    .banner-slide{
        padding-top: 80px;
    }
}


@media screen and (max-width: 580px) {
    .banners-wrapper{
        padding: 0 0px;
    }
    .arrowNav {
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
    .banner-slide{
        padding: 130px 0 0;
    }
    .banner-slide .matchImage{
        max-width: 100%;
        flex: 0 100%;
    }
    .banner-slide .matchImage img{
        max-width: 80%;
    }
    .banner-slide .matchinner{
        width: 100%;
        flex: 0 100%;    
        position: relative;
        z-index: 3;
        padding: 0 20px 80px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0.8) 60%, rgba(0,0,0,0) 100%);
    }
    .banners-wrapper{
        min-height: 1px;
    }
    .banner-slide  .League{
        font-size: 20px;
    }
    .banner-slide  .teams{
        font-size: 16px;
    }
    .banner-slide  .schedule{
        font-size: 14px;
    }
    .banner-slide  .isLive {
        font-size: 12px;
    }
    .banner-slide .matchwrapper{
        padding: 10px 0;
        margin-bottom: 15px;
    }
}

.banner-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.banner-settings form{
    width: 100%;
}
.upload-area {
    width: 100%;
    max-width: 1440px;
    height: 509px;
    background-color: #e0e0e0;
    border: 2px dashed #999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    position: relative;
}

.upload-area img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.upload-area span {
    font-size: 18px;
    font-weight: bold;
    color: #666;
}

.input-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.input-section label {
    margin-right: 10px;
    font-weight: bold;
    color: #fff;
}

.input-section input[type="text"] {
    width: 200px;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-section button {
    padding: 10px 20px;
    background-color: #ffc107;
    color: #000;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.input-section button:hover {
    background-color: #ffcd38;
}


.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    background-color: #ffe4e4;
    border:1px solid red ;
    padding: 10px;
}
.banner-display{
    position: relative;
}
.banner-display .banner-image{
    width: 100%;
    height: 200px;
    position: relative;
    z-index: 1;
    object-fit: cover;
    object-position: center ;
}
.banner-display > a{
    position: absolute;
    z-index: 2;
}
.banner-display > .banner-Text{
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.banner-display > .banner-Text img{
    max-width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 20px;
}
.banner-button{
    position: relative;
    display: block;
    background-color: var(--accent-color);
    padding: 12px 25px;
    text-decoration: none !important;
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 30px;
    border: 2px solid var(--accent-color);
    transition: all 200ms linear;
    text-align: center;
    max-width: max-content;
    margin: 10px auto;
    transition: all 0.4s linear;
}
.banner-button:hover{
background-color: var(--accent-color-50);
}
@media screen and (min-width: 767px) and (max-width: 1279px) {
    .banner-display > .banner-Text{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 9;
        width: 40%;
    }
    .banner-display .banner-image{
        height: auto !important;
    }
}

@media screen and (min-width: 1280px) {
    .banner-display > .banner-Text{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 9;
        width: 35%;
        margin: 0 auto;
    }
    .banner-display .banner-image{
        height: auto !important;
    }
}
.banner-area{
    position: relative;
}
.banner-area .bannerText-area{
    background-color: hsla(0, 0%, 100%, .103);
    height: 90%;
    left: 50%;
    max-width: calc(780px - 15%);
    opacity: .9;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
}

.bannerImg-area::after{
    content: "CLICK HERE TO UPDATE BANNER IMAGE";
    display: block;
    position: absolute;
    font-size: 30px;
    font-weight: 600;
    color: #000;
    background-color: #fff;
    padding: 10px;
    right: 10%;
    max-width: 400px;
    opacity: 0.4;
    transition: all 200ms linear;
}
.bannerText-area.upload-area span{
    color: #fff !important;
}

.bannerImg-area:hover:after, .bannerText-area:hover:after{
    opacity: 1;
}
.btn-Clear{
    position: absolute;
    top: 10px;
    right: 10px;
}