.LiveSettings{
    max-width: 95%;
    margin: 50px auto;
   
}

.LiveSettings > .form-group{
    margin: 15px 0;
    display: flex;
    gap: 10px;
}

.TableHeader{
    background-color: var(--text-w-color);
    border-radius: 8px 8px 0 0;
    padding:  10px;
}

.selected-match-details{
    display: list-item;
    column-count: 2;
}