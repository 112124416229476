
.blog-post-container{
    padding: 50px 0;
    color: #fff;
}
.blog-post-container form{
    display: block;
    max-width: 95%;
    margin: 0 auto;
}
.blog-post-container form label, .blog-post-container form input{
    display: block;
    width: 100%;
}

.blog-post-container .container {  
    display: grid;
    grid-template-columns: 1.3fr 0.5fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
        "main sidebar";
    width: 100%;
    margin: 20px 0;

}
  
.blog-post-container .main { 
    grid-area: main;
    padding: 20px;
    background-color: var(--black-20);
    border-radius: 15px;
}
  
.blog-post-container .sidebar { 
    grid-area: sidebar; 
    padding: 20px;
    background-color: var(--black-20);
    border-radius: 15px;
}
.blog-post-container .form-control{
    padding: 5px 0;
}

.blog-post-container .form-control input, .blog-post-container .form-control select {
    border-radius: 8px;
    padding: 10px;
    border: none;
    margin-top: 5px;
    width: 100%;
}
.form-control .quill {
    margin-top: 5px;
   
}
.form-control .quill  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
}
.ql-container{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
}
.blog-post-container .form-control .ql-editor{
    min-height: 200px;
    color: #000;
}

.blog-post-container form button[type="submit"]{
    background-color: var(--accent-color);
    border-radius: 30px;
    min-width: 140px;
    padding: 10px 20px;
    border: 1px solid var(--accent-color);
}

.dropzone {
    background-color: #f9f9f9;
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.2s ease;
    margin-top: 5px;
}
.dropzone {
    display: flex;
    align-items: center;
}
.dropzone > p{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 5px;
}

.dropzone:hover {
    background-color: #eeeeee;
}
.dropzone p{
    color: #000;
}