header{

    color: #fff;
    z-index: 9999;
    position: relative;
}
.logo{
    max-width: 140px;
}
.nav-bar{
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: all 0.8s;
    position: absolute;
    padding: 16px 24px;
    width: calc(100% - 310px);
    left: 310px;
    background-color: var(--secondary-color);
    z-index: 9999;
}
.nav.body-collapse {
    width: calc(100% - 0px);
    left: 0px;
}
.nav-button{
    cursor: pointer;
}
#nav-main{
    position: fixed;
    width: 310px;
    position: fixed;
    height: 100vh;
    top: 0;
    left: -130%;
    transition: all 0.7s;
    padding: 32px 32px;
    overflow-y: auto;
    background-color: var(--third-color);
    z-index: 99999;
}
#nav-main.active{
    left: 0;
}
.nav-links{
    display: flex;
    flex-direction: column;
    
}
.nav-links a{
    color: var(--text-w-color);
    text-decoration: none;
    padding: 15px 20px;
    border-radius: 18px;

}
.nav-links a:hover{
    background-color: var(--primary-color);
    color: var(--accent-color);
}