.livestream-container{
    padding: 60px 0;
}
.stream-match{
    width: 95%;
    margin: 0 auto;
}

.stream-container{
    display: grid; 
    grid-template-columns: 1.8fr 0.7fr; 
    grid-template-rows: 1fr; 
    gap: 10px; 
    grid-template-areas: 
      "Live Chat"
      "Match Chat"; 
}
.match-data{
    padding: 30px;
    background-color: var(--black-60);
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    grid-area: Match;
    width: 100%;
}

.match-details{
    display: flex;
    justify-content: center;
    gap: 10px;
    color: var(--text-w-color);
}

.stream-container .video-wrapper{
    grid-area: Live;
    border-radius: 10px;
    overflow: hidden;
}
.stream-container .video-wrapper > div{
    max-width: 100%;
}
.stream-container .liveChat{
    grid-area: Chat;
    padding: 10px;
    background-color: var(--dark-gray);
    border-radius: 10px;
}

.stream-container .liveChat iframe{
    padding-top: 20px;
    height: calc(100% - 30px);
    border: 0;
    width: 100%;

}
@media screen and (max-width: 767px) {
    .stream-container{
        grid-template-columns: auto; 
        grid-template-rows: auto; 
        grid-template-areas: 
        "Live"
        "Match"
        "Chat"; 
    }
}



@media screen and (max-width: 767px) {
    .live-wrapper{
        display: flex;
        flex-wrap: wrap;
    }
    .live-wrapper > div{
        flex: 0 100%;
    }
    .stream-container .video-wrapper{
        height: 355px;
    }
    .stream-container .video-container{
        height: 355px;
        margin-top: 0;
        position: relative;
        z-index: 1;
    }
    .stream-container  .liveChat {
        height: 350px;
    }
    
}

@media screen  and (max-width: 1024px){
    .stream-container .video-container.iframe-fullscreen{
        position: fixed;
        height: 100%;
        z-index: 9999;
    }
}

@media screen  and (max-width: 768px) and (orientation: portrait){
    .stream-container .video-container{
        top: 50%;
        left: 50%;
         transform: translate(-50%,-50%); 
    }
    .stream-container .video-container.iframe-fullscreen{
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        background-color: black;
        max-width: unset;
        width: 100vh;
        height: 100vw;
        transform: translate(-50%,-50%) rotate(90deg);
    }  


    .live-container .video-container{
        top: 50%;
        left: 50%;
         transform: translate(-50%,-50%); 
    }
    .live-container .video-container.iframe-fullscreen{
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        background-color: black;
        max-width: unset;
        width: 100vh;
        height: 100vw;
        transform: translate(-50%,-50%) rotate(90deg);
    }  
    
    .iframe-fullscreen .fullscreenIcon{
        right: 30px !important;
    } 
}

@media screen and (max-width: 932px)  and (orientation: landscape){
    .stream-container .video-container.iframe-fullscreen{
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        background-color: black;
        max-width: unset;
        width: 100vw;
        height: 100vh;
        transform: translate(-50%,-50%) rotate(0deg);
    }  
    .live-container .video-container.iframe-fullscreen{
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        background-color: black;
        max-width: unset;
        width: 100vw;
        height: 100vh;
        transform: translate(-50%,-50%) rotate(0deg);
    }  
    .iframe-fullscreen .fullscreenIcon{
        bottom: 20px !important;
    }  
}